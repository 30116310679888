import { useEffect } from 'react'
import { navigate } from 'gatsby'
import availableLangs from '@constants/availableLangs'

const IndexPage = () => {
  useEffect(() => {
    if (!!window) {
      let currentLang = window.navigator.language
      if (currentLang.includes('-')) {
        currentLang = currentLang.split('-')[0]
      }
      navigate(`/${availableLangs[currentLang] || availableLangs.en}`)
    }
  }, [])
  return null
}

export default IndexPage
